/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Third party
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'

// Images
import Checkmark from 'img/checkmark.svg'

// Interface
import { UspProps } from 'components/flex/Usp/UspShell'

const Section = styled.section`
  font-family: ${({ theme }) => theme.font.family.primary};
  position: relative;
  z-index: 2;

  & ul {
    list-style: none;

    & li {
      position: relative;
      margin-bottom: 1rem;
      &:before {
        position: absolute;
        content: url(${Checkmark});
        top: 0;
        left: -30px;
      }
    }
  }
`

const Image = styled(Plaatjie)`
  height: 90px;
`

const StyledBlockPrimary = styled(BlockPrimary)`
  position: relative;
  z-index: 3;

  @media (min-width: 992px) {
    & h2 {
      min-height: 77px;
    }
  }
`

const UspDetail: React.FC<UspProps> = ({ fields }) => (
  <Section>
    <div className="container py-5">
      <div className="row">
        <ParseContent content={fields.description} className="mb-5" />
        {fields.usps?.map(
          ({ usp, uspdescription, uspicon }: any, index: number) => (
            <div className="col-sm-6 col-md-4 mb-4" key={index}>
              <StyledBlockPrimary className="h-100">
                <div className="d-flex flex-column align-items-center">
                  <h2 className="mb-4 text-center">{usp}</h2>
                  <Image image={uspicon} alt="" className="mb-5" />
                  <ParseContent content={uspdescription} />
                </div>
              </StyledBlockPrimary>
            </div>
          )
        )}
      </div>
    </div>
  </Section>
)

export default UspDetail
