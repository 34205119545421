/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Third party
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'

// Images
import ArrowRight from 'img/arrowright.inline.svg'

// Interface
import { UspProps } from 'components/flex/Usp/UspShell'
import NavLink from 'components/shared/NavLink'

const Section = styled.section`
  font-family: ${({ theme }) => theme.font.family.primary};
  position: relative;
  z-index: 2;
`

const Row = styled.div`
  background-color: #f4f4f4;
  position: relative;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background-color: #f4f4f4;
  }
`

const StyledBlockPrimary = styled(BlockPrimary)`
  width: 130px;
  height: 130px !important;
  border-radius: 50%;
  aspect-ratio: 1/1;

  @media (min-width: 768px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.large};
    }
  }

  @media (max-width: 767.98px) {
    width: 120px;
    height: 120px !important;
    margin-bottom: 2rem !important;

    & h2 {
      font-size: ${({ theme }) => theme.font.size.medium};
    }
  }

  @media (max-width: 575.98px) {
    width: 110px;
    height: 110px !important;
  }
`

const StyledNavLink = styled(NavLink)`
  transition: transform 0.2s ease;
  &:hover {
    transform: scale(1.1);
  }
`

const UspCircles: React.FC<UspProps> = ({ fields }) => (
  <Section className="mb-5 pb-5">
    <div className="container py-5">
      <Row className="row p-4">
        <div className="col-lg-5 d-flex justify-content-center align-items-center">
          <ParseContent content={fields.description} className="mb-4" />
        </div>
        <div className="col-lg-7 d-flex align-items-center justify-content-between">
          <div className="row w-100 justify-content-center">
            {fields.usps?.map((usp: any, index: number) => (
              <div
                className="col-6 col-md-3 d-flex justify-content-center"
                key={index}
              >
                <StyledNavLink to={usp.link.url}>
                  <StyledBlockPrimary className="h-100 d-flex flex-column justify-content-center align-items-center p-0 mb-0">
                    <h2 className="text-center font-weight-bold mb-4 px-3">
                      {usp.usp}
                    </h2>
                    <ArrowRight />
                  </StyledBlockPrimary>
                </StyledNavLink>
              </div>
            ))}
          </div>
        </div>
      </Row>
    </div>
  </Section>
)

export default UspCircles
