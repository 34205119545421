/* eslint-disable react/no-array-index-key */
import React from 'react'

// Third party
import styled from 'styled-components'
import { motion } from 'framer-motion'
import useMedia from 'use-media'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

// Images
import Circle from 'img/circle.inline.svg'
import BackgroundCircle from 'img/backgroundcircle.inline.svg'

// Interface
import { UspProps } from 'components/flex/Usp/UspShell'
import { useInView } from 'react-intersection-observer'
import NavLink from 'components/shared/NavLink'

const Section = styled.section`
  position: relative;
  font-family: ${({ theme }) => theme.font.family.primary};
`

const Usp = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  color: ${({ theme }) => theme.color.secondary} !important;

  & span {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.huge};
    }
  }

  @media (min-width: 992px) {
    width: 175px;
    height: 175px;
  }
  @media (max-width: 991px) {
    width: 90px;
    height: 90px;
  }
`

const Center = styled.div`
  position: relative;
  z-index: 2;
  @media (min-width: 992px) {
    padding-top: 13rem;
    margin-bottom: 13rem;
  }
  @media (max-width: 991px) {
    padding-top: 6rem;
    margin-bottom: 6rem;

    & svg {
      width: 100px;
      height: 100px;
    }
  }
`

const Usps = styled.div`
  position: absolute;
  top: 0;
  left: calc(50% - 87.5px);
  z-index: 5;
`

const Content = styled(ParseContent)`
  @media (max-width: 575px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.medium};
    }
  }
`

const BackgroundWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  top: -1700px;
  left: 0;
  z-index: -1;
  padding-left: 0 !important;
  padding-right: 0 !important;
`

const StyledNavLink = styled(NavLink)`
  position: relative;
  z-index: 99;
`

const UspCarousel: React.FC<UspProps> = ({ fields }) => {
  const isDesktop = useMedia({ minWidth: 992 })

  const [hovered, setHovered] = React.useState(-1)

  const { ref, inView } = useInView({
    threshold: 0,
  })

  return (
    <Section className="mb-5 pb-5">
      <div className="container">
        <Content content={fields.description} className="mb-5" />
        <div className="row justify-content-center">
          <BackgroundWrapper>
            <BackgroundCircle />
          </BackgroundWrapper>
          <Usps>
            {fields.usps?.map(({ usp, link }: any, index) => {
              let initialX: number
              let initialY: number

              switch (index) {
                case 1:
                  initialX = isDesktop ? 130 : 40
                  initialY = isDesktop ? -20 : -60
                  break
                case 2:
                  initialX = isDesktop ? -30 : -80
                  initialY = isDesktop ? -2 : -120
                  break
                case 3:
                  initialX = isDesktop ? -500 : -360
                  initialY = isDesktop ? -278 : -310
                  break
                case 4:
                  initialX = isDesktop ? -650 : -480
                  initialY = isDesktop ? -845 : -630
                  break
                default:
                  initialX = isDesktop ? -10 : 30
                  initialY = isDesktop ? 100 : 50
              }

              const uspAnimation = {
                x: initialX + index * 100,
                y: initialY + index * 100,
                opacity: 1,
                scale: hovered === index ? 1.05 : 1,
              }

              return (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={/* inView && */ uspAnimation}
                  // transition={{ delay: index * 0.5 }} R.I.P. 🥲
                  style={{ maxWidth: 175 }}
                  onHoverStart={() => setHovered(index)}
                  onHoverEnd={() => setHovered(-1)}
                >
                  <StyledNavLink to={link.url || '#'}>
                    <Usp className="d-flex justify-content-center align-items-center p-3">
                      <span className="text-center">{usp}</span>
                    </Usp>
                  </StyledNavLink>
                </motion.div>
              )
            })}
          </Usps>
          <Center className="d-flex justify-content-center" ref={ref}>
            <Circle />
          </Center>
        </div>
        <div className="text-center mt-5">
          <ButtonPrimary to={fields.link?.url}>
            {fields.link?.title}
          </ButtonPrimary>
        </div>
      </div>
    </Section>
  )
}

export default UspCarousel
