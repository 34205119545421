import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div<{ hoverable: boolean; stretchRight: boolean }>`
  position: relative;
  background: ${({ theme }) => theme.color.primary};
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-bottom: 2rem;
  transition: box-shadow 0.3s ease-in-out;

  @media (min-width: 992px) {
    padding: 2.5rem 9.5rem 2.5rem 2.5rem;
  }
  @media (max-width: 991px) {
    padding: 2rem 1rem;
  }

  ${(props) =>
    props.stretchRight &&
    css`
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    `}

  &:hover {
    ${(props) =>
      props.hoverable &&
      css`
        box-shadow: unset;
      `}
  }

  &:after {
    content: '';
    position: absolute;
    ${(props) =>
      props.stretchRight &&
      css`
        right: unset;
        left: 100%;
      `}
    right: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background-color: ${({ theme }) => theme.color.primary};
  }
`

interface BlockSecondaryProps {
  hoverable?: boolean
  stretchRight?: boolean
  className?: string
}

const BlockSecondary: React.FC<BlockSecondaryProps> = ({
  className = '',
  children,
  hoverable = false,
  stretchRight = false,
}) => (
  <Wrapper
    hoverable={hoverable}
    stretchRight={stretchRight}
    className={className}
  >
    <>{children}</>
  </Wrapper>
)

export default BlockSecondary
