/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlockSecondary from 'components/elements/Blocks/BlockSecondary'
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'
import FullCircle from 'components/elements/Vectors/FullCircle'

// Images
import Circle from 'img/circle.svg'
import ArrowDown from 'img/arrowdown.inline.svg'
import Close from 'img/close.inline.svg'

// Interface
import { UspProps } from 'components/flex/Usp/UspShell'

const Section = styled.section`
  font-family: ${({ theme }) => theme.font.family.primary};

  position: relative;
  & ul {
    list-style: none;
  }

  @media (min-width: 1400px) {
    & .container {
      max-width: 1230px !important;
    }
  }
`

const Content = styled(ParseContent)`
  position: relative;
  z-index: 2;

  & h2 {
    font-size: ${({ theme }) => theme.font.size.huge};
  }
`

const Col = styled.div`
  position: relative;
  z-index: 2;
  @media (max-width: 991px) {
    padding-top: 12rem;
  }
  @media (max-width: 575px) {
    padding-top: 7rem;
  }
`

const Title = styled.span`
  position: relative;
  display: inline-block;
  font-weight: ${({ theme }) => theme.font.weight.bold};

  &:before {
    position: absolute;
    left: -2px;
    content: '';
    background-image: url(${Circle});
    background-size: contain;
    background-repeat: no-repeat;
  }

  @media (min-width: 576px) {
    font-size: ${({ theme }) => theme.font.size.mega};

    &:before {
      width: 25px;
      height: 25px;
      top: calc(50% - 8px);
    }
  }
  @media (max-width: 575px) {
    font-size: ${({ theme }) => theme.font.size.large};

    &:before {
      width: 15px;
      height: 15px;
      top: calc(50% - 5px);
    }
  }
`

const BackgroundWrapper = styled.div<{ hasPopout: string }>`
  z-index: 2;
  pointer-events: none !important;

  @media (min-width: 1440px) {
    position: absolute;
    top: -130px;
    right: -125px;
    & svg {
      width: 1000px;
      height: 1000px;
    }
  }

  @media (max-width: 1439px) {
    position: relative;
    top: -1210px;

    & svg {
      position: absolute;
      right: -140px;
      height: 700px;
      width: 700px;
    }
  }

  @media (max-width: 1199.98px) {
    top: -1010px;
  }

  @media (max-width: 991px) {
    ${(props) =>
      props.hasPopout
        ? css`
            display: none;
          `
        : css`
            top: -1010px;
            right: 80px;
          `}

    & svg {
      height: 400px;
      width: 400px;
    }
  }

  @media (max-width: 767.98px) {
    ${(props) =>
      props.hasPopout &&
      css`
        top: -1170px;
        right: 70px;
      `}
  }

  @media (max-width: 575px) {
    ${(props) =>
      props.hasPopout
        ? css`
            top: -1100px;
          `
        : css`
            top: -818px;
            right: 100px;
          `}

    & svg {
      height: 300px;
      width: 300px;
    }
  }

  @media (max-width: 461.98px) {
    ${(props) =>
      props.hasPopout &&
      css`
        top: -1150px;
      `}
  }

  @media (max-width: 389.98px) {
    ${(props) =>
      props.hasPopout
        ? css`
            & svg {
              height: 200px;
              width: 200px;
            }
          `
        : css`
            top: -830px;
          `}
  }
`

const StyledBlockSecondary = styled(BlockSecondary)``

const StyledBlockPrimary = styled(BlockPrimary)``

const UspDefault: React.FC<UspProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(0)

  const descriptionVariants = {
    open: {
      opacity: 1,
      height: 'auto',
    },
    collapsed: {
      opacity: 0,
      height: 0,
    },
  }

  return (
    <Section className="mb-lg-5 pb-5" id={fields.sectionid}>
      <div className="container py-5">
        <div className="row">
          <Col className="col-lg-8">
            <StyledBlockSecondary className="ps-0">
              <Content content={fields.description} className="mb-4 ms-lg-4" />
              <ul className="p-0">
                {fields.usps?.map(({ usp, uspdescription }: any, index) => {
                  const open: boolean = currentIndex === index
                  let cursor = 'default'

                  if (uspdescription) {
                    cursor = 'pointer'
                  }

                  return (
                    <li key={index}>
                      <div
                        onClick={() =>
                          open ? setCurrentIndex(-1) : setCurrentIndex(index)
                        }
                        role="button"
                        onKeyDown={() => setCurrentIndex(index)}
                        tabIndex={-1}
                        style={{ cursor }}
                      >
                        {!uspdescription && (
                          <StyledBlockPrimary className="p-4 ms-lg-4 overflow-visible">
                            <Title>&nbsp;&nbsp;{usp}</Title>
                          </StyledBlockPrimary>
                        )}
                        {uspdescription && (
                          <StyledBlockPrimary className="p-4 ms-lg-4 overflow-visible">
                            <div className="d-flex justify-content-between align-items-center">
                              <Title>&nbsp;&nbsp;{usp}</Title>
                              {!open && <ArrowDown />}
                              {open && <Close />}
                            </div>
                            <AnimatePresence>
                              {open && (
                                <motion.div
                                  initial="collapsed"
                                  animate="open"
                                  exit="collapsed"
                                  variants={descriptionVariants}
                                  transition={{
                                    duration: 0.15,
                                    ease: [0.04, 0.62, 0.23, 0.98],
                                  }}
                                >
                                  <ParseContent
                                    content={uspdescription}
                                    className="py-4"
                                  />
                                </motion.div>
                              )}
                            </AnimatePresence>
                          </StyledBlockPrimary>
                        )}
                      </div>
                    </li>
                  )
                })}
              </ul>
              {fields.link && (
                <div className="d-flex justify-content-center">
                  <ButtonSecondary to={fields.link?.url || '#'}>
                    {fields.link?.title}
                  </ButtonSecondary>
                </div>
              )}
            </StyledBlockSecondary>
          </Col>
        </div>
      </div>
      <BackgroundWrapper hasPopout={fields.usps![0]?.uspdescription || ''}>
        <FullCircle image={fields.image} />
      </BackgroundWrapper>
    </Section>
  )
}

export default UspDefault
