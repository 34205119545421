import React from 'react'

// Components
import UspDefault from 'components/flex/Usp/UspDefault'
import UspCarousel from 'components/flex/Usp/UspCarousel'
import UspDetail from 'components/flex/Usp/UspDetail'
import UspCircles from 'components/flex/Usp/UspCircles'

export interface UspProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_UniqueSellingPoints
  inViewport?: boolean
  forwardedRef?: any
  location?: any
}

interface PostProps {
  [key: string]: any
}

const UspShell: React.FC<UspProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    default: UspDefault,
    detail: UspDetail,
    carousel: UspCarousel,
    circles: UspCircles,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default UspShell
