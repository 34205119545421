import React from 'react'

const FullCircle = ({ image }: any) => (
  <svg
    width="853"
    height="1042"
    viewBox="0 0 853 1042"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <mask
      id="maskfullcircle"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="1042"
      height="1042"
    >
      <g clipPath="url(#clipfullcircle)">
        <path
          d="M590.202 300.382C625.821 311.465 658.206 331.056 684.558 357.46C727.888 400.886 752.222 459.727 752.222 521.072C752.222 582.418 727.888 641.259 684.558 684.685C658.206 711.089 625.821 730.679 590.202 741.763L590.202 882.78C662.689 869.078 729.35 833.805 781.455 781.581C925.103 637.933 925.103 404.212 781.455 260.563C729.348 208.342 662.688 173.07 590.202 159.365L590.202 300.382Z"
          fill="#082E53"
        />
        <path
          d="M453.232 742.181C417.031 731.185 384.108 711.415 357.388 684.63C314.058 641.204 289.724 582.364 289.724 521.018C289.724 459.673 314.058 400.832 357.388 357.406C384.108 330.621 417.031 310.852 453.232 299.856L453.268 159.057C380.21 172.545 312.982 207.934 260.509 260.527C116.861 404.176 116.861 637.897 260.509 781.545C312.982 834.138 380.21 869.528 453.268 883.016L453.232 742.181Z"
          fill="#FCCF14"
        />
      </g>
    </mask>
    <g mask="url(#maskfullcircle)">
      <rect
        x="-85.3973"
        y="152.597"
        width="1105.21"
        height="736.805"
        fill="url(#patternfullcircle)"
      />
      <rect
        x="-330"
        y="139"
        width="1344"
        height="756"
        fill="url(#patternfullcircle)"
      />
    </g>
    <defs>
      <pattern
        id="patternfullcircle"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#circleimage"
          transform="translate(-0.000573389) scale(0.00103211 0.00183486)"
        />
      </pattern>
      <clipPath id="clipfullcircle">
        <rect
          width="736.805"
          height="736.805"
          fill="white"
          transform="translate(521) rotate(45)"
        />
      </clipPath>
      <image
        id="circleimage"
        width="970"
        height="545"
        xlinkHref={image?.localFile?.publicURL}
      />
    </defs>
  </svg>
)

export default FullCircle
